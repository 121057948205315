<template>
  <div>
    <vs-row class="mt-5">
      <vs-col vs-w="12">
        <div
          class="helloCard py-4 px-6 flex items-center justify-between rounded-md mb-5"
        >
          <p class="text-xl text-primary">
            Olá 👋, <strong>{{ userName }}</strong
            >!
            {{ fullDate }}
          </p>

          <vs-button
            icon-pack="feather"
            icon="icon-chevrons-right"
            icon-after
            to="/apps/inbox"
          >
            Acessar Inbox
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <div class="w-full mt-4" v-if="$acl.check('manager')">
      <div class="vx-row">
        <CardContainer
          cardTitle="Campanhas"
          cardHeader="Conversas"
          cardIcon="SendIcon"
          key="cardCampaign"
          :statsDiff="0"
          :cardValue="newData.conversations.totals[0].totalCampaign"
          :modalText="
            statsData.conversationCampaign +
            ' é o número de conversas de campanhas.'
          "
        />

        <CardContainer
          cardHeader="Conversas"
          cardTitle="Aguardando atendimento"
          :cardValue="newData.conversations.totals[0].totalWaitingAttendance"
          :cardIcon="'ClockIcon'"
          :modalText="`${statsData.waitingAttendant} conversas estão esperando atendimento`"
          :statsDiff="0"
          :key="`card1-${chatContactsKey}`"
        />

        <CardContainer
          cardHeader="Conversas"
          cardTitle="Em atendimento"
          :cardIcon="'MessageSquareIcon'"
          :modalText="`${statsData.inAttendance} conversas estão em atendimento nesse momento`"
          :cardValue="newData.conversations.totals[0].totalInAttendance"
          :statsDiff="0"
        />

        <CardContainer
          cardHeader="Conversas"
          cardTitle="Total"
          :cardValue="newData.conversations.totals[0].total"
          :cardIcon="'UsersIcon'"
          :modalText="
            statsData.inAttendance +
            statsData.waitingAttendant +
            ' é o total de conversas ativas'
          "
          :statsDiff="0"
        />
      </div>
      <div class="vx-row" v-if="$acl.check('manager')">
        <div
          class="vx-col w-full max-h-full sm:w-full md:w-full lg:w-1/4 xl:w-1/4 h-full"
        >
          <CardUsersOnlineV2
            :key="`card1-${chatContactsKey}`"
            :cardTitle="'Usuários da Conta'"
            :cardData="newData.userList"
          />
        </div>
        <div class="vx-col w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4">
          <CardConversationsTable
            :key="`card2-${chatContactsKey}`"
            :cardTitle="'Conversas por Intenção'"
            :cardData="newData.conversations.conversationByIntention"
          />
          <CardConversationsTable
            :key="`card3-${chatContactsKey}`"
            :cardTitle="'Conversas por Entidade-chave'"
            :cardData="newData.conversations.conversationByEntity"
          />
          <CardConversationsTable
            :key="`card4-${chatContactsKey}`"
            :cardTitle="'Conversas por Atendente'"
            :cardData="newData.conversations.conversationByOperator"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardContainer from "./components/CardContainerV2.vue";
import CardConversationsTable from "./components/CardConversationsTable.vue";
import CardUsersOnlineV2 from "./components/CardUsersOnlineV2.vue";

export default {
  components: {
    CardContainer,
    CardConversationsTable,
    CardUsersOnlineV2,
  },
  data() {
    return {
      interval: null,
      fullDate: "",
      userName: this.$store.state.user.name.split(" ")[0],
      operatorUserName: null,
      statsData: {
        conversationCampaign: 0,
        waitingAttendant: 0,
        inAttendance: 0,
        userList: [],
        conversationByIntention: [],
        conversationByEntity: [],
        conversationByAttendant: [],
      },
      newData: {
        conversations: {
          conversationByIntention: [],
          conversationByEntity: [],
          conversationByOperator: [],
        },
        userList: [],
      },
      totals: {
        totalCampaign: 0,
        totalWaitingAttendance: 0,
        totalInAttendance: 0,
        total: 0,
      },
      chatContactsKey: 0,
      dataAvailable: false,
      useV2: true,
    };
  },
  created() {
    this.interval = setInterval(this.realTime, 1000);
    this.getHomeDataV2();
  },
  methods: {
    async getHomeData() {
      await this.$http
        .get("/p/chat/inbox/new-dashboard/home", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((res) => {
          this.dataAvailable = true;
          this.statsData = res.data.data;
          this.chatContactsKey = 1;
        });
    },
    async getHomeDataV2() {
      await this.$http
        .get("/p/chat/inbox/new-dashboard-v2/home", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            acl: this.$store.state.user.userRole,
          },
        })
        .then((res) => {
          this.dataAvailable = true;
          this.newData = res.data.data;
          this.totals = res.data.data.conversations.totals[0];
          this.chatContactsKey = 1;
        });
    },
    realTime() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = today.toLocaleString("pt-BR", { month: "long" });
      const year = today.getFullYear();
      const hour = String(today.getHours()).padStart(2, "0");
      const minutes = String(today.getMinutes()).padStart(2, "0");
      this.fullDate = `Agora é ${day} de ${month} de ${year} às ${hour}:${minutes}`;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.vx-row {
  display: flex;
  justify-content: flex-end;
}

.helloCard {
  background-image: linear-gradient(
      0deg,
      rgba(115, 103, 240, 0.21),
      rgba(115, 103, 240, 0.21)
    ),
    linear-gradient(0deg, #7367f0ff, #7367f0ff);
  background-size: auto, 5px 100%;
  background-repeat: repeat, no-repeat;
}
</style>
